.h1 {
  @apply font-bold text-4xl tracking-tighter;
  line-height: 1.125;
}

.h2 {
  @apply font-medium text-3xl tracking-tighter;
  line-height: 1.125;
}
.h3 {
  @apply font-semibold text-3xl leading-tight tracking-tighter;
}
.h4 {
  @apply font-medium text-2xl leading-tight;
}
.h5 {
  @apply font-medium text-xl leading-tight;
}
.h6 {
  @apply uppercase font-semibold text-base leading-tight;
}
.subheading {
  @apply font-medium text-base;
}
@screen md {
  .h1 {
    @apply text-5xl;
  }
}

@screen lg {
  .h1 {
    @apply text-6xl;
  }

  .h2 {
    @apply text-5xl;
  }
  .h3 {
    @apply text-4xl;
  }
  .h4 {
    @apply text-3xl;
  }
  .h5 {
    @apply text-2xl;
  }
  .h6 {
    @apply text-lg;
  }
  .subheading {
    font-size: 1.125rem;
  }
  .path-line-gradient-lg {
    background: linear-gradient(var(--start-color), var(--end-color));
    border-right: 0;
  }
}
:root {
  --color-primary-dark: #214541;
  --color-primary: #008878;
  --color-primary-light: #ddeeec;
  --color-on-primary: #ffffff;
  --color-accent-dark: #2a1c4a;
  --color-accent: #7a51d9;
  --color-accent-light: #f6f4fa;
  --color-accent-light: #f6f4fa;
  --color-on-accent: #ffffff;
  --color-background: #fff;
  --color-background-soft: #rgba(255, 255, 255, 0.7);
  --color-foreground: #121212;
  --color-foreground-soft: rgba(0, 0, 0, 0.7);

  --font-display: Inter, Trebuchet MS, Arial, sans-serif;
  --font-body: Inter, Trebuchet MS, Arial, sans-serif;

  --font-weight-normal: 400;
  --font-weight-display: 600;
  --font-weight-btn: 600;

  --rounded-btn: 1rem;
  --rounded-btn-small: 0.5rem;
  --rounded-btn-tiny: 0.25rem;
  --rounded-multiple-choice: 1rem;
  --rounded-single-choice: 2rem;
  --rounded-text-field: 0.25rem;
  --geist-icons-background: #fff;
}

.navbar-blur {
  backdrop-filter: saturate(120%) blur(6px);
}

.glass-container {
  backdrop-filter: blur(4px);
  @apply absolute inset-0;
}

.input {
  @apply text-xs;
}
.input::placeholder {
  @apply text-xs;
}

.toggle.disabled {
  @apply border-gray-200 bg-gray-50;
}

.toggle.disabled > .inner {
  @apply bg-gray-200;
}

.toggle.disabled.checked {
  @apply border-gray-500 bg-gray-500;
}

.toggle.checked {
  @apply bg-primary;
}

@screen md {
  .input::placeholder {
    @apply text-base;
  }
  .input {
    @apply text-base;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hidden-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* nprogress.css */

/* Make clicks pass-through */
#nprogress {
  pointer-events: @apply pointer-events-none;
}

#nprogress .bar {
  @apply bg-primary fixed top-0 left-0 w-full;
  z-index: 2000;
  height: 2px;
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
